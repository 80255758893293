import request from '@/utils/request'

// 关于我们 分页
export function dataPage(query) {
  return request({
    url: '/wisdom/wisdom-about-us/page',
    method: 'get',
    params: query
  })
}

// 关于我们 详情
export function objInfo(query) {
  return request({
    url: '/wisdom/wisdom-about-us/detail',
    method: 'get',
    params: query
  })
}

// 关于我们 编辑
export function objEdit(data) {
  return request({
    url: '/wisdom/wisdom-about-us/edit',
    method: 'post',
    data
  })
}
